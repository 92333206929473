<template>
  <v-container>
    <v-card>
      <v-card-title class="display-2 mb-5">Create Expert</v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <v-row>
            <v-col md="4">
              <v-text-field
                label="Full Name"
                required
                v-model="user.name"
                outlined
                dense
                hide-details="auto"
                :rules="requiredRule('Name')"
              ></v-text-field>
            </v-col>
            <v-col md="4">
              <v-text-field
                label="Email"
                required
                v-model="user.email"
                outlined
                dense
                hide-details="auto"
                :rules="requiredRule('Email')"
              ></v-text-field>
            </v-col>
            <v-col md="4">
              <v-text-field
                label="Phone Number"
                required
                v-model="user.phone"
                outlined
                dense
                hide-details="auto"
                :rules="requiredRule('Phone Number')"
              ></v-text-field>
            </v-col>

            <!-- <v-col md="4">
              <v-text-field
                label="Username"
                required
                v-model="user.username"
                outlined
                dense
                hide-details="auto"
                :rules="requiredRule('Username')"
              ></v-text-field>
            </v-col> -->

            <v-col md="4">
              <!-- <v-text-field
              label="Admin Profile"
              required
              v-model="user.adminProfileId"
              outlined
              dense
              hide-details="auto"
            ></v-text-field> -->

              <v-select
                :items="departments"
                label="Department"
                item-text="name"
                item-value="id"
                outlined
                dense
                hide-details="auto"
                v-model="user.departmentId"
                :rules="requiredRule('User Profile')"
              ></v-select>
            </v-col>

            <v-col md="4">
              <v-file-input
              outlined
              dense
                @change="changeFile"
                label="NEC Certificate"
                hide-details="auto"
              ></v-file-input>
            </v-col>

            <v-col md="4">
              <v-text-field
                label="NEC"
                type="text"
                required
                v-model="user.necNumber"
                outlined
                dense
                hide-details="auto"
                :rules="requiredRule('NEC')"
              ></v-text-field>
            </v-col>

            <v-col md="4">
              <v-text-field
                label="password"
                type="password"
                required
                v-model="user.password"
                outlined
                dense
                hide-details="auto"
                :rules="requiredRule('Password')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-btn color="primary" @click="uploadFile">Save</v-btn>
  </v-container>
</template>



<script>
export default {
  data() {
    return {
      user: {},
      file: null,

      valid: false,
      departments: [],
      nameRules: [(v) => !!v || "Name is required"],
    };
  },
  methods: {
    requiredRule(field) {
      return [(v) => !!v || `${field} is required`];
    },
    changeFile(args) {
      this.file = args;
    },

    uploadFile() {
      // this.loading = true;
      if(this.file){
           if(this.valid){
         let formData = new FormData();
      formData.append("file", this.file);
      this.$File
        .uploadFile(formData)
        .then((data) => {
          this.file = null;
          this.save(data.data.data)
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
      }else{
        this.$refs.form.validate();
      }
      }else{
        this.save();
      }
   
     
    },


    save(filename) {
      if (this.valid) {
        this.user.necCertificateFile = filename? filename : null;
        this.$Expert
          .createExpert(this.user)
          .then((data) => {
            this.$toastr.success("User Created Successfully");
            this.$router.push({ path: "/expert/" + data.data.data.id });
          })
          .catch((error) => {
            this.$toastr.error(
              error.response.data.message
                ? error.response.data.message
                : "Something Went Wrong"
            );
          });
      } else {
        this.$refs.form.validate();
      }
    },
    edit() {
      if (this.valid) {
        this.$Users
          .editClientUser(this.user)
          .then((data) => {
            this.$toastr.success("User Edited Successfully");
            this.$router.push({ path: "/admin" });
          })
          .catch((error) => {
            this.$toastr.error(error.response.data.message);
            console.log(error);
          });
      } else {
        this.$refs.form.validate();
      }
    },
    getUserById() {
      this.$Users
        .getUserById(this.$route.params.id)
        .then((data) => {
          this.user = data.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getDepartment() {
      this.$Admin
        .getDepartments()
        .then((data) => {
          this.departments = data.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted() {
    this.getDepartment();
  },
};
</script>

<style scoped>
</style>